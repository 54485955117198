import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import useVariaStaticQuery from './useVariaStaticQuery'
import * as containerStyles from './Varia.module.scss'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

export const quotes = {
  ceo: {
    name: 'Dmytro',
    position: 'CEO & Founder at Codica',
  },
  be: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewMalleniTitle = 'Custom E-commerce Solution'
const previewIxTitle = 'Online travel marketplace'

const CaseStudiesVaria = () => {
  const query = useVariaStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Custom SaaS platform for real estate"
          subtitle="The SaaS allows for showing real estate listings with 3D virtual tours. The real estate portal lets users see an apartment on the screen as if they are already there. You can post a listing and change details if needed on the platform."
          maxWidthSubtitle="420px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Desktop and mobile versions of real estate SaaS by Codica"
          imageTitle="Real estate SaaS platform by Codica"
          domain="Real estate"
          location="Israel"
          timeline="June 2020 - February 2021"
          services={
            <>
              <Link
                to="/services/progressive-web-apps-development/"
                key="pwaDevKey"
              >
                PWA development
              </Link>
              ,
              <Link
                to="/services/saas-development/"
                className="ml5"
                key="saasDevKey"
              >
                SaaS development
              </Link>
              , Front-end development, Back-end development
            </>
          }
          team="Project manager, 1 Ruby on Rails developer, 2 React developers, QA, DevOps engineer"
          technologies="Ruby on Rails, React, Gatsby, PostgreSQL, AWS"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“The task was to create a fast-loading and user-friendly SaaS for real estate. The SaaS needed to include features allowing users to view the real estate property and communicate with the homeowners.',
            },
            {
              text: 'Codica carefully approached the SaaS platform development and implemented the needed functionality. We put maximum effort and technical knowledge into achieving the goals set in the specification.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Sergey.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'As part of their activities, the company needed to create a property search website displaying real estate listings. The entity offers the services of making 3D tours that accompany property listings. So, it was necessary to include 3D tours along with the real estate SaaS functionality. This way, the visitor can get a realistic impression of the property.',
        ]}
        bgTextRight={[
          'Our goal was to create a multi-page custom SaaS platform for real estate that displayed listings from homeowners. The platform needed to be fast-loading, responsive, and intuitive, with the possibility to add images and 3D tours.',
        ]}
        sectionBusiness={[
          {
            label:
              'Develop a responsive and intuitive real estate portal that handles various data about the property.',
          },
          {
            label:
              'Create two versions of the portal in English and Hebrew languages.',
          },
          {
            label:
              'Include the possibility of adding and showing images and 3D tours around the property.',
          },
          {
            label:
              'Build and implement functionality for admin and user account management of the portal.',
          },
          {
            label:
              'Deliver a fast-loading online real estate platform that performs equally well on all types of devices.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Codica created a real estate SaaS that presents property with different details in various locations and within a certain price range. The website helps to place and find real estate property with the given parameters, such as property type, number of bedrooms, and location.',
          },
          {
            label:
              'Our team added the feature of 3D tours that enable you to see the apartment or home on the SaaS-based platform before going onsite.',
          },
          {
            label:
              'We included helpful forms for homeowners, agents, and tenants that allow easy communication. After looking through the property description, users can submit their questions to the homeowner.',
          },
          {
            label:
              'Developers introduced the JAMstack approach, including the Gatsby technology, which helped us deliver a responsive, fast-loading, and intuitive SaaS for real estate. These technologies were especially valuable as the platform needed to handle much data.',
          },
          {
            label:
              'The SaaS solution for real estate loads smoothly on desktop and mobile devices, delivering the best user experience.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“When the client turned to us asking to develop the platform, we were excited to solve the problem. We applied the Gatsby technology for the SaaS platform development. It helped us create a portal that loads fast despite the many elements it handles.',
            },
            {
              text: 'Our team made the interface that is easy to use and provides all the necessary information to get an impression of the property listed. We enjoyed working on the project as it brought us new experience and knowledge that we can apply in the future.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/CEO.png"
              alt={`${quotes.ceo.name}, ${quotes.ceo.position} at Codica`}
              title={`${quotes.ceo.name}, ${quotes.ceo.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.ceo.name}
          position={quotes.ceo.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need an experienced team to build a custom web platform?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="A fast-loading platform for all types of devices"
          description="We delivered a platform with prompt loading of pages. Thanks to our approach, the real estate portal presenting many property listings with images and 3D models works seamlessly. So, users can easily access many pages on the portal from devices of any type."
          alt="Fast-loading platform for all types of devices | Codica"
          imageTitle="Fast-loading platform for all types of devices by Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Versatile user account management"
          description="A user can view and change the details of their accounts and listings. We created a step-by-step form that allows listing a property. You can enter all the needed information on the SaaS-based platform, such as property type, size, number of rooms, amenities, selling price, and dates. Also, you can add photos and 3D tours to present your property fully."
          alt="Versatile user account management for real estate portal | Codica"
          imageTitle="Versatile user account management for real estate SaaS | Codica"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="3D tour presenting the property in detail"
          description="This feature enhances the user experience with the real estate SaaS, letting them see the whole property on the screen. This allows you to get the first impression and have a detailed look at the property without going on site. 3D tours save users time by being accessible 24/7 through various platforms."
          alt="3D tour presenting the property in detail for real estate portal | Codica"
          imageTitle="3D tour presenting the property in detail for real estate SaaS | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isImageSmall
          isTextWhite
          image={solutionDeliveredFour}
          title="Intuitive page showing the property details"
          description="We included the pages with all details that are relevant to the property. For example, you can view the price and other fees needed to buy the property on the real estate SaaS platform. Also, we included the display of amenities accompanied by minimalistic icons."
          alt="Intuitive page showing the property details for real estate portal | Codica"
          imageTitle="Intuitive page showing the property details for real estate SaaS | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Map integration revealing the neighborhood"
          description="Integration with maps allows seeing the property neighborhood. Hovering on a location on the map, you can highlight the relevant property and go to the property page. If a user has questions on the property, they can submit them in a form and send them to a homeowner."
          alt="Map integration revealing the neighborhood for real estate portal | Codica"
          imageTitle="Map integration revealing the neighborhood for real estate SaaS | Codica"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Swift search displaying the needed property"
          description="We delivered the search system that brings up the available property by location. We integrated the map search showing the property locations. You can see the property address and price when hovering on the location point."
          alt="Swift search displaying the needed property for real estate portal | Codica"
          imageTitle="Swift search displaying the needed property for real estate SaaS | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredSeven}
          title="Simple switch between English and Hebrew versions"
          description="The custom SaaS platform for real estate presents its features and property listings in English and Hebrew. We successfully implemented right-to-left and left-to-right writing. Also, we adapted both language versions for mobile devices."
          alt="Simple switch between English and Hebrew versions for real estate portal | Codica"
          imageTitle="Simple switch between English and Hebrew versions for real estate SaaS | Codica"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The SaaS platform offers a convenient viewing of the listed real estate. As a homeowner or an agent, you can enter the necessary details and, thus, list the property. You can add 3D tours that show your home or apartment in detail. As a tenant, you can view the property and contact the owner or agent for details. The custom real estate SaaS platform is adapted both for desktop and mobile devices."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/3Zcg9TYDebE"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'React JS', icon: 'react' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'Gatsby', icon: 'gatsby' },
            { label: 'AWS', icon: 'aws' },
          ]}
          integrations={[
            { label: 'Google Translate API', icon: 'googleTranslateApi' },
            { label: 'Zoho CRM integration', icon: 'zohoCrmIntegration' },
            { label: 'Zoho Live Chat', icon: 'zohoLiveChat' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“We introduced the JAMstack technology group to organize the solution architecture. This approach enabled us to deliver a flexible, scalable, and secure solution that is easy to update, patch, and maintain.',
            },
            {
              text: 'Thanks to the top-notch technologies implemented during the SaaS platform development for real estate, the product works fast and delivers the best of what users expect. This experience helped us discover new paths to bring users a solution they enjoy.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Sergey.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewMalleniTitle}
              color="malleniColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-malleni.png"
                  alt={`Case study: ${previewMalleniTitle} | Codica`}
                  title={`Case study: ${previewMalleniTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="custom-multi-vendor-marketplace-platform"
            />
            <CaseStudyPreview
              title={previewIxTitle}
              color="ixColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-iexpedition.png"
                  alt={`Case study: ${previewIxTitle} | Codica`}
                  title={`Case study: ${previewIxTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="travel-management-platform"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesVaria
