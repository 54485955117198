// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_kr";
export var caseStudyBackground__lineColor = "bj_kp";
export var caseStudyHead = "bj_kj";
export var caseStudyHead__imageWrapper = "bj_kk";
export var caseStudyProjectsSection = "bj_ks";
export var caseStudyQuote__bgLight = "bj_kt";
export var caseStudyQuote__bgRing = "bj_km";
export var caseStudyVideo__ring = "bj_kv";
export var caseStudy__bgDark = "bj_kh";
export var caseStudy__bgLight = "bj_kg";